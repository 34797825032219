<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class=" print:hidden bg-gradient-to-t from-amber-300 to-blue-300"
    [appearance]="navigationAppearance"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex flex-auto items-center mt-4 mb-8 justify-center h-20">
            <img *ngIf="navigationAppearance === 'dense'"
                class="w-24"
                src="assets/images/logo/logo.png"
                alt="Logo image">
            <img *ngIf="navigationAppearance === 'default'"
                class="w-30"
                src="assets/images/logo/logo.png"
                alt="Logo image">
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0 bg-blue-50">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b print:hidden">
        <div class="flex items-center pr-2 space-x-2">
            <!-- Navigation toggle button -->
            <button
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
            <!-- Navigation appearance toggle button -->
            <button
                class="hidden md:inline-flex"
                mat-icon-button
                (click)="toggleNavigationAppearance()">
                <mat-icon [svgIcon]="'heroicons_outline:switch-horizontal'"></mat-icon>
            </button>
            <p class="ml-2 md:text-3xl text-md text-extrabold font-extrabold tracking-tight leading-none">{{currenttab.tab}}</p>
            <p class="ml-2 md:text-2xl text-sm text-bold text-secondary" *ngIf="currenttab.showcount && currenttab.count>0">({{currenttab.count}})</p>
    
        </div>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <button *ngIf="showadd && buttontext !== 'RO Onboard' && user && user.role !== 'Viewer'"
            mat-flat-button color="primary"
            (click)="add('NA')">
            <mat-icon class="hidden md:block" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
            <span class="ml-2 mr-1">{{buttontext}}</span>
            </button>
            
            <button *ngIf="showadd && buttontext === 'RO Onboard' && user && user.role !== 'Viewer'" 
            mat-flat-button color="primary"
            [matMenuTriggerFor]="roonboard">
            <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
            <!-- <span>Status</span> -->
            <span class="ml-2 mr-1">{{buttontext}}</span>
            </button>

            <div class="flex shrink-0 items-center sm:ml-4" *ngIf="currenttab.tab === 'ALERTS'">
                <form [formGroup]="formalerts">
                <mat-button-toggle-group matInput formControlName="dash" name="dash" toggleType="radio" aria-label="Font Style">
                    <mat-button-toggle value="Open" >Open</mat-button-toggle>
                    <mat-button-toggle value="All">All</mat-button-toggle>
                </mat-button-toggle-group>
                </form>
            </div>

            <mat-menu
            class="user-status-menus"
            #roonboard="matMenu">
            <button
                mat-menu-item
                (click)="add('manual')">
                <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
                <span>Create Manually</span>
            </button>
            <button
                mat-menu-item
                (click)="add('excel')">
                <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
                <span>Excel Upload</span>
            </button>
        </mat-menu>
        <div class="flex shrink-0 items-center sm:ml-4" *ngIf="currenttab.tab === 'DASHBOARD'">
            <form [formGroup]="form">
            <mat-button-toggle-group matInput *ngIf="user && (user.role === 'SuperAdmin' || user.role === 'ClientAdmin' || user.role === 'ServerAdmin' )" formControlName="dash" name="dash" toggleType="radio" aria-label="Font Style">
                <mat-button-toggle value="RO" >RO</mat-button-toggle>
                <mat-button-toggle value="DSM">Sales</mat-button-toggle>
            </mat-button-toggle-group>
            </form>
        </div>
        <div *ngIf="currenttab.tab !== 'DASHBOARD' && currenttab.tab !== 'REPORTS' && currenttab.tab !== 'SUMMARY'">
            <button *ngIf="columns && columns.length>0 && formalerts.value.dash != 'All'"
                class="min-w-10 min-h-7 h-7 px-2 leading-6"
                
                [matMenuTriggerFor]="filterMenu">    
                
                <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'heroicons_solid:search'"></mat-icon>
            </button>
         


            <button *ngIf="formalerts.value.dash != 'All'"
            color="primary" class="md:min-w-10 md:min-h-7 md:h-7 md:px-2 mt-1 md:leading-6" 
            [matMenuTriggerFor]="refreshinterval">
            <div class="flex flex-col justify-center items-center">
            <mat-icon [svgIcon]="'refresh'"></mat-icon>
            <p class="text-secondary text-xs">{{this.refreshmap[this.refreshtime]}}</p>
            </div>
            </button>

            <mat-menu
            class="user-status-menus"
            #refreshinterval="matMenu">
            <button
                mat-menu-item id="now" name="now"
                (click)="refresh(0)">
                <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
                <span>Now</span>
            </button>
            <button
                mat-menu-item id="1min" name="1min"
                (click)="refresh(60000)">
                <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
                <span>1 Min</span>
            </button>
            <button
                mat-menu-item id="5min" name="5min"
                (click)="refresh(300000)">
                <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
                <span>5 Min</span>
            </button>
            </mat-menu>

            <mat-menu #filterMenu="matMenu">
                <mat-card class="pt-2 pb-2 filter-card max-100 flex flex-wrap" (click)="$event.stopPropagation();">
                  <h3 class="mb-1 ml-4">Filter</h3>
                  <mat-card-content>
                    <form *ngIf="columns && columns.length>0">
                      <ng-container *ngFor="let column of columns;let i = index">
                        <mat-form-field *ngIf="column && column.issearch && !column.showCalander && !column.showDates " class="w-60"
                          >
                          <input matInput type="text" placeholder="Search {{column.header}}"
                            *ngIf="!column.selectFilter && !column.showCalander && column.filter !== 'map'" name="{{column.column}}"
                            [(ngModel)]="colfilter[column.column]"
                            (input)='columnFilter($any($event.target).value,column.column, column)'
                            >
                          <mat-select placeholder="Choose {{column.header}}" name="{{column.column}}"
                            *ngIf="column.filterdatalist && column.filterdatalist.length>0" [(ngModel)]="colfilter[column.column]"
                            (selectionChange)="columnFilter($event.value, column.column, column)">
                            <mat-option value="">All</mat-option>
                            <mat-option *ngFor="let val of column.filterdatalist" [value]="val.value">{{val.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                      
                        <mat-form-field *ngIf="column && column.issearch && column.showCalander " class="w-60">
                            <input matInput placeholder="Choose {{column.header}}" onkeypress="return false" name="{{i}}{{column.column}}" id="{{i}}{{column.column}}" [(ngModel)]="colfilter[column.column]" (dateChange)="columnFilter($event.value, column.column, column)" [ngxMatDatetimePicker]="picker" name="{{i}}start_time_moment" id="{{i}}start_time_moment" placeholder="Select Date and Time">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <ngx-mat-datetime-picker #picker [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
                            [touchUi]="touchUi" [color]="" [enableMeridian]="enableMeridian"></ngx-mat-datetime-picker>
                            <button mat-icon-button matSuffix mat-clear (click)="clearDatetimePicker(i, column)">
                                <mat-icon>close</mat-icon>
                              </button>
                          </mat-form-field>

                          
                        
                      </ng-container>
                    </form>
                  </mat-card-content>
                </mat-card>
              </mat-menu>


            </div>
            <!--<languages></languages>-->
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <!--<search [appearance]="'bar'"></search>-->
            <!--<shortcuts></shortcuts>
            <messages></messages>-->
            <!-- <notifications></notifications> -->
            <!--<button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button>-->
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto bg-white">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div class="relative flex flex-0 bg-gray-50 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t print:hidden">
        <span class="font-medium text-secondary">BPCL &copy; {{currentYear}}</span>
    </div>

</div>

<!-- Quick chat -->
<!--<quick-chat #quickChat="quickChat"></quick-chat>-->
